import {
  getDefaultsCostInputsGrid,
  getDefaultsPvSystem,
  getDefaultsTariffs,
  useSite,
} from "@inrange/building-manager-api-client";
import { useEffect, useRef } from "react";

export const useSetDefaultsOnCurrencyChange = (site, setSite, currencyCode) => {
  const { fetchSiteValues } = useSite({});
  const siteRef = useRef(site);
  siteRef.current = site;
  useEffect(() => {
    if (!fetchSiteValues.data) {
      // Site values not loaded yet
      return;
    }
    if (siteRef.current.currencyCode === currencyCode) {
      // Not change from initial site state
      return;
    }

    // Currency code has changed - reset to default values for the new country
    const defaultsPvSystem = getDefaultsPvSystem(
      fetchSiteValues.data.options.pvSystems,
      currencyCode,
      siteRef.current.pvInverterBrand,
      siteRef.current.pvPanelName
    );
    const defaultsCostInputsGrid = getDefaultsCostInputsGrid(
      fetchSiteValues.data.options.costInputsGrids,
      currencyCode
    );
    const defaultsTariffs = getDefaultsTariffs(
      fetchSiteValues.data.options.tariffsDatas,
      currencyCode
    );
    setSite({
      costInputsPv: defaultsPvSystem.costInputsPv,
      costInputsGrid: defaultsCostInputsGrid,
      marketTariff: defaultsTariffs.marketTariff,
      tenantTariff: defaultsTariffs.tenantTariff,
      inrangeExportTariff: defaultsTariffs.inrangeExportTariff,
      // Remove battery to avoid having to do a currency conversion here
      batteryModel: null,
      batteryPower: null,
      batteryCapacity: null,
      batteryEnergyFlowPayloadHash: null,
      batteryEnergyFlowFileVersionId: null,
      costInputsBattery: {
        ...siteRef.current.costInputsBattery,
        batteryCost: 0,
      },
    });
  }, [setSite, fetchSiteValues.data, currencyCode]);
};
