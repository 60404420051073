import area from "@turf/area";
import booleanIntersects from "@turf/boolean-intersects";
import { point, polygon } from "@turf/helpers";
import inside from "@turf/inside";
import { transformTranslate } from "@turf/transform-translate";
import * as L from "leaflet";
import { latLngBounds } from "leaflet";
import "leaflet-editable";
import "leaflet.path.drag";
import "leaflet/dist/leaflet.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { GeoJSON, MapContainer, Pane, useMap } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { v4 } from "uuid";

const solidBlueStyle = {
  fillOpacity: 0.1,
  color: "#3388ff",
  weight: 2,
  opacity: 1,
};

const solidDarkBlueStyle = {
  fillOpacity: 0.1,
  color: "#0000ff",
  weight: 2,
  opacity: 1,
};

const outlineGreenStyle = {
  color: "green",
  fillOpacity: 0,
  weight: 4,
  opacity: 1,
  interactive: false,
};

const GMAPS_API_KEY = import.meta.env.VITE_GMAPS_API_KEY;

const layerToCoordinates = (layer, multi) => {
  const polygonToCoords = (polygon) => {
    return polygon.map((point) => [point.lng, point.lat]);
  };
  if (multi) {
    return layer
      .getLatLngs()
      .map((multiPolygon) => multiPolygon.map(polygonToCoords));
  }
  return layer.getLatLngs().map(polygonToCoords);
};

const coordinatesToMultiPolygonGeoJson = (coordinates, ids) => {
  return {
    type: "Feature",
    properties: { ids },
    geometry: {
      type: "MultiPolygon",
      coordinates: coordinates,
    },
  };
};

const coordinatesToPolygonGeoJson = (coordinates) => {
  return {
    type: "Polygon",
    coordinates: coordinates,
  };
};

const getClosedCoordinates = (coordinates) => {
  if (coordinates[0][0] === coordinates[0][coordinates[0].length - 1]) {
    return coordinates;
  }
  const closedCoordinates = [...coordinates];
  closedCoordinates[0].push(closedCoordinates[0][0]);
  return closedCoordinates;
};

const useEventListeners = (
  map,
  deleteCustomObject,
  newCustomObjects,
  updateNewCustomObjects,
  selectedObjectIDs,
  setSelectedObjectIDsHandleLeafletStateChange
) => {
  useEffect(() => {
    if (!map) {
      return;
    }

    const deleteSelectedObjects = () => {
      for (const id of selectedObjectIDs) {
        deleteCustomObject(id);
      }
      setSelectedObjectIDsHandleLeafletStateChange(map, [], false);
    };

    const duplicateSelectedObjects = () => {
      const newSelectedIds = [];
      for (const selectedObjectID of selectedObjectIDs) {
        const selectedGeoJson = coordinatesToPolygonGeoJson(
          newCustomObjects[selectedObjectID].coordinates
        );
        const duplicateGeoJson = transformTranslate(selectedGeoJson, 5, 135, {
          units: "meters",
        });
        const duplicateId = v4();
        updateNewCustomObjects(duplicateId, duplicateGeoJson);
        newSelectedIds.push(duplicateId);
      }
      setSelectedObjectIDsHandleLeafletStateChange(map, newSelectedIds, false);
    };

    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        map.editTools.stopDrawing();
        // set cursor to pointer
        map.getContainer().style.cursor = "default";
      }
      if (event.key === "Escape") {
        map.editTools.stopDrawing();
      }

      if (event.key === "Delete") {
        deleteSelectedObjects();
      }

      if (event.key === "Backspace") {
        deleteSelectedObjects();
      }

      if (event.key === "d") {
        // check if mac or windows
        if (navigator.platform.match("Mac")) {
          if (event.metaKey) {
            event.preventDefault();
            duplicateSelectedObjects();
          }
        }
        if (event.ctrlKey) {
          event.preventDefault();
          duplicateSelectedObjects();
        }
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        // set cursor to crosshair
        map.getContainer().style.cursor = "crosshair";
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    const updateMouseMoveDrawSelectionRectangle = (event) => {
      if (!event.originalEvent.shiftKey) {
        map.removeEventListener(
          "mousemove",
          updateMouseMoveDrawSelectionRectangle
        );
      } else {
        map.getContainer().style.cursor = "crosshair";
        if (map.editTools._dragSelectionLayerStart) {
          const bounds = L.latLngBounds(
            map.editTools._dragSelectionLayerStart,
            event.latlng
          );
          const dragSelectionLayer = map.editTools._dragSelectionLayer;
          if (dragSelectionLayer) {
            dragSelectionLayer.setBounds(bounds);
          } else if (map.editTools._dragSelectionLayerStart) {
            const dragSelectionLayer = L.rectangle(bounds, {
              color: "#0000ff",
              weight: 1,
            });
            const selectionPoly = polygon(
              getClosedCoordinates(
                layerToCoordinates(dragSelectionLayer, false)
              )
            );
            const selectionPolyArea = area(selectionPoly);
            if (selectionPolyArea > 1) {
              // Avoid creating a selection rectangle that is too small as this can happen
              // when the user is just trying to click while pressing shift
              dragSelectionLayer.addTo(map);
              map.editTools._dragSelectionLayer = dragSelectionLayer;
            }
          }
        }
      }
    };

    const handleMapMouseDown = (event) => {
      if (!event.originalEvent.shiftKey) {
        map.getContainer().style.cursor = "grabbing";
      } else {
        if (!map.editTools.drawing()) {
          map.getContainer().style.cursor = "crosshair";
          map.editTools._dragSelectionLayerStart = event.latlng;
          map.on("mousemove", updateMouseMoveDrawSelectionRectangle);
        }
      }
    };

    const handleMapMouseUp = (event) => {
      map.removeEventListener(
        "mousemove",
        updateMouseMoveDrawSelectionRectangle
      );
      if (!event.originalEvent.shiftKey) {
        map.getContainer().style.cursor = "crosshair";
      } else {
        if (map.editTools._dragSelectionLayer) {
          map.removeLayer(map.editTools._dragSelectionLayer);
          if (map.editTools._dragSelectionLayer._latlngs[0].length >= 4) {
            const selectionPoly = polygon(
              getClosedCoordinates(
                layerToCoordinates(map.editTools._dragSelectionLayer, false)
              )
            );
            const newSelectedObjectsIDs = [];
            for (const customObject of Object.values(newCustomObjects)) {
              const objCoords = getClosedCoordinates(customObject.coordinates);
              const objPoly = polygon(objCoords);
              if (booleanIntersects(selectionPoly, objPoly)) {
                newSelectedObjectsIDs.push(customObject.id);
              }
            }
            setSelectedObjectIDsHandleLeafletStateChange(
              map,
              newSelectedObjectsIDs,
              true
            );
          }
          map.editTools._dragSelectionLayer = undefined;
          map.editTools._dragSelectionLayerStart = undefined;
        }
      }
    };

    map.on("mousedown", handleMapMouseDown);
    map.on("mouseup", handleMapMouseUp);

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      map.off("mousedown", handleMapMouseDown);
      map.off("mouseup", handleMapMouseUp);
      map.off("mousemove", updateMouseMoveDrawSelectionRectangle);
    };
  }, [
    map,
    deleteCustomObject,
    updateNewCustomObjects,
    newCustomObjects,
    selectedObjectIDs,
    setSelectedObjectIDsHandleLeafletStateChange,
  ]);
};

const MapHook = ({ bounds }) => {
  const map = useMap();
  map.invalidateSize();

  map.dragging.enable();
  const boundsRef = useRef();
  const prevBounds = boundsRef.current;
  // We zoom the map if the computed bounds change
  const shouldZoomMap = prevBounds === undefined || !prevBounds.equals(bounds);
  boundsRef.current = bounds;
  useEffect(() => {
    if (shouldZoomMap) {
      map.fitBounds(boundsRef.current);
    }
  }, [map, shouldZoomMap]);
  return null;
};

const PVCustomObjectsMap = ({
  buildings,
  siteCenter,
  newCustomObjects,
  updateNewCustomObjects,
  deleteCustomObject,
}) => {
  const [map, setMap] = useState(null);
  const [selectedObjectIDs, setSelectedObjectIDs] = useState([]);

  const setSelectedObjectIDsHandleLeafletStateChange = useCallback(
    (map, newSelectedObjectIDs, markSelectedItemEditable) => {
      setSelectedObjectIDs(newSelectedObjectIDs);
      map.eachLayer((layer) => {
        if (
          markSelectedItemEditable &&
          newSelectedObjectIDs.length == 1 &&
          layer.options.id === newSelectedObjectIDs[0]
        ) {
          // When we select a single item, we re-use the existing layer, so we need to handle the
          // editable state change explicitly
          if (layer.enableEdit && !layer.editEnabled()) {
            layer.enableEdit();
          }
        }
        // Mark non-selected items as non-editable, but still draggable
        if (
          layer.options.id &&
          !newSelectedObjectIDs.includes(layer.options.id)
        ) {
          if (layer.disableEdit && layer.editEnabled()) {
            layer.disableEdit();
            // Make sure layer is still draggable, even if not editable
            layer.dragging.enable();
          }
        }
      });
    },
    [setSelectedObjectIDs]
  );

  const bounds = latLngBounds([siteCenter]);

  buildings.forEach((building) => {
    let coordinates = building.geometry.coordinates;
    if (typeof coordinates === "string") {
      coordinates = JSON.parse(building.geometry.coordinates);
    }
    coordinates[0].forEach((coord) => {
      bounds.extend(coord.reverse());
    });
  });

  useEventListeners(
    map,
    deleteCustomObject,
    newCustomObjects,
    updateNewCustomObjects,
    selectedObjectIDs,
    setSelectedObjectIDsHandleLeafletStateChange
  );

  const onClickPolygon = (event) => {
    const map = event.target._map || event.target;

    // Click handler for when the shift key is not pressed
    if (!event.originalEvent.shiftKey) {
      // User clicked a polygon, so handle the click in the polygon instead and don't draw a new polygon
      map.editTools.stopDrawing();
      map.dragging.disable();

      const clickLatLng = event.latlng;
      for (const customObject of Object.values(newCustomObjects)) {
        if (
          inside(
            point([clickLatLng.lng, clickLatLng.lat]),
            polygon(customObject.coordinates)
          )
        ) {
          setSelectedObjectIDsHandleLeafletStateChange(
            map,
            [customObject.id],
            true
          );
          // Don't need to continue the loop as we found a polygon to select/deselect
          break;
        }
      }
      return;
    } else {
      // Click handler for when the shift key *is* pressed
      const layer = event.propagatedFrom;
      const clickLatLng = event.latlng;
      const featureType = layer?.feature?.geometry?.type;

      if (featureType === "Polygon") {
        // featureType === "Polygon" -> user clicked a single polygon, which may be selected or de-selected
        const id = layer.options.id;
        if (selectedObjectIDs.includes(id)) {
          // User clicked a selected polygon, need to deselect it
          setSelectedObjectIDsHandleLeafletStateChange(
            map,
            selectedObjectIDs.filter(
              (selectedObjectsID) => selectedObjectsID !== id
            ),
            true
          );
        } else {
          // User clicked a de-selected polygon, need to select it
          setSelectedObjectIDsHandleLeafletStateChange(
            map,
            [...selectedObjectIDs, id],
            true
          );
        }
      } else {
        // featureType === "MultiPolygon" -> user clicked a selected polygon, need to deselect it
        for (const selectedObjectID of selectedObjectIDs) {
          const coordinates = newCustomObjects[selectedObjectID].coordinates;
          const closedCoordinates = getClosedCoordinates(coordinates);
          if (
            inside(
              point([clickLatLng.lng, clickLatLng.lat]),
              polygon(closedCoordinates)
            )
          ) {
            // User clicked inside this polygon, need to deselect it
            const newSelectedObjectsIDs = selectedObjectIDs.filter(
              (selectedObjectsID) => selectedObjectsID !== selectedObjectID
            );
            setSelectedObjectIDsHandleLeafletStateChange(
              map,
              newSelectedObjectsIDs,
              true
            );

            // Don't need to continue the loop as we found a polygon to deselect
            break;
          }
        }
      }
    }
  };

  const updateNewCustomObjectsFromEvent = (e) => {
    const layer = e.layer || e.target;
    const geoJson = layer.toGeoJSON();
    updateNewCustomObjects(layer.options.id, geoJson.geometry);
  };

  const onEditExistingEnd = (e) => {
    const layer = e.layer || e.target;

    if (layer.options.id === undefined && layer.options.ids === undefined) {
      // This isn't one of our layers, return early
      return;
    }

    const featureType = layer.feature?.geometry?.type;
    if (featureType !== "MultiPolygon") {
      // Dragging a single polygon
      updateNewCustomObjectsFromEvent(e);
    } else {
      // Dragging the selected layer which may contain multiple polygons
      const ids = layer.feature.properties.ids;
      const newLatLngs = layerToCoordinates(layer, true);
      for (const [index, id] of ids.entries()) {
        const closedCoordinates = getClosedCoordinates(newLatLngs[index]);
        const polygonGeoJson = coordinatesToPolygonGeoJson(closedCoordinates);
        updateNewCustomObjects(id, polygonGeoJson);
      }
    }
  };

  const onDrawMouseClicked = (event) => {
    // This gets triggered after the user has completed their first click, so this is where we can deselect any selected polygons
    const map = event.target._map || event.target;
    const drawingLayer = map.editTools._drawingEditor;
    if (drawingLayer && drawingLayer._drawnLatLngs.length > 0) {
      setSelectedObjectIDsHandleLeafletStateChange(map, [], true);
    }
    return;
  };

  const onDrawCancel = (event) => {
    const polygonLayer = event.layer;
    const map = event.target._map || event.target;
    map.removeLayer(polygonLayer);
  };

  const onDrawCommit = (event) => {
    const polygonLayer = event.layer;

    // editable:drawing adds a Polygon layer, remove this, add the polygon to NewCustomObjects, and mark it as selected
    const layerCoordinates = layerToCoordinates(polygonLayer, false);
    const map = event.target._map || event.target;
    map.removeLayer(polygonLayer);
    const newCustomObject = {
      id: v4(),
      coordinates: getClosedCoordinates(layerCoordinates),
    };
    setSelectedObjectIDsHandleLeafletStateChange(
      map,
      [newCustomObject.id],
      false
    );

    // Record the new custom object into React state
    updateNewCustomObjects(
      newCustomObject.id,
      coordinatesToPolygonGeoJson(newCustomObject.coordinates)
    );
  };

  const onMapLoad = (event) => {
    const map = event.target;

    // Map click
    map.on("click", (event) => {
      /*
      classList: user clicked a polygon -> ["leaflet-interactive", "leaflet-path-draggable"]
      classList: user clicked the map -> ["leaflet-container", "leaflet-touch", "leaflet-retina",
                                          "leaflet-fade-anim", "leaflet-grab", "leaflet-touch-drag",
                                          leaflet-touch-zoom", "leaflet-editable-drawing" ]
      */
      if (
        event.originalEvent.srcElement.classList.contains(
          "leaflet-container"
        ) &&
        !event.originalEvent.shiftKey
      ) {
        if (!map.editTools.drawing()) {
          // User clicked the map, start a polygon
          map.editTools.startPolygon(event.latlng);
          setSelectedObjectIDsHandleLeafletStateChange(map, [], true);
        }
      }
    });

    // Drawing
    map.on("editable:drawing:clicked", onDrawMouseClicked);
    map.on("editable:drawing:cancel", onDrawCancel);
    map.on("editable:drawing:commit", onDrawCommit);

    // Dragging
    map.on("editable:vertex:deleted", onEditExistingEnd);
    map.on("editable:vertex:dragend", onEditExistingEnd);
    map.on("editable:dragend", onEditExistingEnd);

    // Automate setting "editEnabled" and enabling dragging for new editable layers
    map.on("layeradd", (e) => {
      const layer = e.layer;

      if (layer.options.id === undefined && layer.options.ids === undefined) {
        // This isn't one of our layers, return early
        return;
      }

      if (layer.options.editable && layer.enableEdit && !layer.editEnabled()) {
        layer.enableEdit();
        layer.dragging.enable();
      }
      if (
        (layer.options.draggable ||
          // When a single polygon is selected, the layer is editable, and needs the following drag handlers,
          // but we don't need to do this for the multi-polygon layer as it's handled separately
          (layer.options.editable && !layer.options.ids)) &&
        layer.dragging &&
        layer.dragging.enable &&
        // Vertex's generated by leaflet editable get the same options as their parent layer,
        // but we do not want to add the same event handlers. The icon option is exclusive
        // to these vertices.
        !layer.options.icon
      ) {
        layer.dragging.enable();
        layer.on("click", onClickPolygon);
        layer.on("dragstart", (e) => {
          // Mark the dragged layer as selected
          setSelectedObjectIDsHandleLeafletStateChange(
            map,
            [e.target.options.id],
            false // Don't mark the layer as editable now, we do that at dragend
          );
        });
        layer.on("dragend", (e) => {
          // Mark the dragged layer as editable
          map.eachLayer((layer) => {
            if (layer.options.id === e.target.options.id) {
              if (layer.enableEdit && !layer.editEnabled()) {
                layer.enableEdit();
              }
            }
          });
          onEditExistingEnd(e);
        });
      }
    });
  };

  return (
    <MapContainer
      center={siteCenter}
      zoom={18}
      style={{
        width: "800px",
        height: "60vh",
        margin: "0 0 10px",
        cursor: "crosshair",
      }}
      whenReady={onMapLoad}
      ref={setMap}
      editable={true}
      boxZoom={false}
    >
      <MapHook bounds={bounds} />
      <ReactLeafletGoogleLayer apiKey={GMAPS_API_KEY} type={"satellite"} />
      <GeoCustomObjects
        newCustomObjects={newCustomObjects}
        selectedObjectIDs={selectedObjectIDs}
        onClick={onClickPolygon}
      />
      <GeoMultiSelectedCustomObjects
        newCustomObjects={newCustomObjects}
        selectedObjectIDs={selectedObjectIDs}
        style={solidDarkBlueStyle}
        onClick={onClickPolygon}
      />
      <Pane zIndex={500} name="site-boundaries">
        <GeoBuildings buildings={buildings} />
      </Pane>
    </MapContainer>
  );
};

export default PVCustomObjectsMap;

const GeoCustomObjects = ({ newCustomObjects, selectedObjectIDs, onClick }) => {
  return Object.values(newCustomObjects)
    .filter(
      (customObject) =>
        selectedObjectIDs.length <= 1 ||
        !selectedObjectIDs.includes(customObject.id)
    )
    .map((customObject) => {
      const isSingleSelectedObject =
        selectedObjectIDs.length === 1 &&
        selectedObjectIDs[0] === customObject.id;
      // Setting "key={"custom-object-" + customObject.id}" is critical to ensure that React knows the correct element to update
      // rather than simply using the array index which would cause React to update the wrong element.
      return (
        <GeoJSON
          key={"custom-object-" + customObject.id}
          data={customObject}
          style={isSingleSelectedObject ? solidDarkBlueStyle : solidBlueStyle}
          eventHandlers={{
            click: onClick,
          }}
          draggable={isSingleSelectedObject ? false : true}
          editable={isSingleSelectedObject ? true : false}
          id={customObject.id}
        />
      );
    });
};

const GeoMultiSelectedCustomObjects = ({
  newCustomObjects,
  selectedObjectIDs,
  onClick,
}) => {
  const selectedObjectsCoords =
    selectedObjectIDs.length > 1
      ? selectedObjectIDs
          .map((id) => newCustomObjects[id])
          .map((customObject) => customObject.coordinates)
      : [];
  // Setting "key={JSON.stringify(selectedObjectIDs)}" is critical to ensure that React re-creates GeoJSON when the inputs change
  // rather than attempting to update them in place which GeoJSON doesn't support.
  return (
    <GeoJSON
      key={JSON.stringify(selectedObjectIDs)}
      data={coordinatesToMultiPolygonGeoJson(
        selectedObjectsCoords,
        selectedObjectIDs
      )}
      style={solidDarkBlueStyle}
      eventHandlers={{
        click: onClick,
      }}
      editable={true}
      ids={selectedObjectIDs}
    />
  );
};

const GeoBuildings = ({ buildings }) => {
  if (!buildings) {
    return null;
  }

  return Object.values(buildings).map((building, index) => {
    const data = { ...building.geometry };

    if (typeof data.coordinates === "string") {
      data.coordinates = JSON.parse(data.coordinates);
    }

    return (
      <GeoJSON
        key={"building-" + index}
        data={data}
        style={outlineGreenStyle}
      />
    );
  });
};
