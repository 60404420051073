import { Modal, ModalView } from "@inrange/theme-components";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useBlocker, useNavigate, useParams } from "react-router-dom";

import {
  DEFAULT_DEMAND_COEFFICIENTS,
  DEFAULT_DEMAND_PROFILES,
  useSite,
} from "@inrange/building-manager-api-client";
import Loading from "../Loading";

import {
  SiteCalculationsContext,
  SiteCalculationsProvider,
  SitePreview,
} from "@inrange/shared-components";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../auth/UserContext";
import isSiteOwnerOccupied from "../../utils/isSiteOwnerOccupied";
import { useSetDefaultsOnCurrencyChange } from "./siteEditHooks";
import ActionButtons from "./siteSections/ActionButtons";
import ActivityLog from "./siteSections/ActivityLog";
import Battery from "./siteSections/Battery";
import Capex from "./siteSections/Capex";
import Cost from "./siteSections/Cost";
import DealTerms from "./siteSections/DealTerms";
import Demand from "./siteSections/Demand";
import DnoNetwork from "./siteSections/DnoNetwork";
import FinancialReturns from "./siteSections/FinancialReturns";
import Generation from "./siteSections/Generation";
import PvSystem from "./siteSections/PVSystem";
import SiteFiles from "./siteSections/SiteFiles";
import SiteGeo from "./siteSections/SiteGeo/SiteGeo";
import SiteInformation from "./siteSections/SiteInformation";
import SiteNav from "./siteSections/SiteNav";
import Tariff from "./siteSections/Tariff";

const SiteEdit = () => {
  const { siteId } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const { fetchSiteValues, fetchSite, updateSite, deleteSite } = useSite({
    siteId,
  });

  // updatedSite contains only the attributes which have been updated
  const [updatedSite, setUpdatedSite] = useState({});
  // previewSite contains a complete view of the site including updated attributes
  const [previewSite, setPreviewSite] = useState({});

  // Save related state
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [updateHubSpotFromModal, setUpdateHubSpotFromModal] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [hasUnsavedBuildings, setHasUnsavedBuildings] = useState(false);

  // Site bools
  const [isOwnerOccupied, setIsOwnerOccupied] = useState(false);
  const [pvDesignDifference, setPvDesignDifference] = useState(false);

  // Site attribute trackers
  const [addedFiles, setAddedFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [siteFiles, setSiteFiles] = useState([]);
  const [newBuildings, setNewBuildings] = useState([]);
  const [originalBuildings, setOriginalBuildings] = useState([]);
  const [deletedOwnerships, setDeletedOwnerships] = useState([]);
  const [newOwnerships, setNewOwnerships] = useState({});

  const pvDesignFileUploadRef = useRef();

  // useCallback ensures this function value is consistent and hence can be used in a useEffect dependency array with no issues
  const setSiteState = useCallback(
    (update) => {
      setUpdatedSite((prevSite) => ({ ...prevSite, ...update }));
      setPreviewSite((prevPreviewSite) => ({ ...prevPreviewSite, ...update }));
    },
    [setUpdatedSite, setPreviewSite]
  );

  // Once site data is loaded, populate: previewSite, newBuildings, originalBuildings
  useEffect(() => {
    if (fetchSite.data?.site) {
      const site = fetchSite.data?.site;
      setPreviewSite(site);
      const transformedBuildings = [];
      site.buildings.forEach((b) => {
        transformedBuildings.push({
          key: b.id,
          ...b,
        });
      });
      setNewBuildings(transformedBuildings);
      setOriginalBuildings([...site.buildings]);
      setIsOwnerOccupied(isSiteOwnerOccupied(site.siteOwnerships));
      setSiteFiles(site.siteFiles);
    }
  }, [fetchSite.data?.site]);

  const navigationBlocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      Object.keys(updatedSite).length > 0 &&
      currentLocation.pathname !== nextLocation.pathname
  );

  if (
    fetchSite.isLoading ||
    Object.keys(previewSite).length === 0 ||
    fetchSiteValues.isLoading
  ) {
    return <Loading label="Loading site data..." />;
  }

  if (fetchSite.isError || fetchSiteValues.isError) {
    return <div>Error loading the site.</div>;
  }

  // site is the original site data from the API
  const site = fetchSite.data?.site;

  const setBuildingsForPreview = (buildings) => {
    const previewBuildings = buildings.map((b) => {
      return {
        id: b.key,
        surfaceAreaSqM: b.surface_area_sqm,
        siteID: siteId,
        geometry: b.geometry,
      };
    });
    setPreviewSite((prevPreviewSite) => ({
      ...prevPreviewSite,
      buildings: previewBuildings,
    }));
    setNewBuildings(buildings);
  };

  const setOwnerships = ({ ownershipId, selectedID, selectedType }) => {
    if (selectedType === "" || !selectedID) return;
    const updatedNewOwnerships = {
      ...newOwnerships,
      [ownershipId]: {
        orgID: selectedID,
        ownership: selectedType,
      },
    };
    setNewOwnerships(updatedNewOwnerships);

    const updatedOwnerships = [
      ...site.siteOwnerships,
      ...Object.values(updatedNewOwnerships),
    ];
    setIsOwnerOccupied(isSiteOwnerOccupied(updatedOwnerships));
    setPreviewSite((prevPreviewSite) => ({
      ...prevPreviewSite,
      siteOwnerships: updatedOwnerships,
    }));
  };

  const onBuildingProfileChange = (newProfile) => {
    setSiteState({
      buildingProfile: newProfile,
      demandCoefficientKWhPerM2: DEFAULT_DEMAND_COEFFICIENTS[newProfile],
      profileShape: DEFAULT_DEMAND_PROFILES[newProfile],
    });
  };

  const onOwnerRemoved = (ownership) => {
    setDeletedOwnerships([...deletedOwnerships, ownership]);
    // remove ownership from newOwnerships
    const updatedOwnerships = previewSite.siteOwnerships.filter(
      (o) => o.orgID !== ownership.orgID
    );
    setIsOwnerOccupied(isSiteOwnerOccupied(updatedOwnerships));
    setPreviewSite((prevPreviewSite) => ({
      ...prevPreviewSite,
      siteOwnerships: updatedOwnerships,
    }));
  };

  const updateSiteFiles = (updatedAddedFiles, updatedDeletedFiles) => {
    const siteFilesWithoutDeletionsAndAdditions = [...site.siteFiles].filter(
      (file) =>
        !updatedDeletedFiles.some((f) => f.fileGuid === file.fileGuid) &&
        !updatedAddedFiles.some((f) => f.fileGuid === file.fileGuid)
    );
    const updatedSiteFiles = [
      ...siteFilesWithoutDeletionsAndAdditions,
      ...updatedAddedFiles,
    ];

    setSiteFiles(updatedSiteFiles);
  };

  const onFileAdded = (siteFile) => {
    if (siteFile.fileType === "pvDesignImage") {
      const addedFilesWithoutPvDesignImage = addedFiles.filter(
        (f) => f.fileType !== "pvDesignImage"
      );
      const newAddedFiles = [...addedFilesWithoutPvDesignImage, siteFile];
      setAddedFiles(newAddedFiles);
      const oldPvDesign = siteFiles.find((f) => f.fileType === "pvDesignImage");

      const newDeletedFiles = [...deletedFiles, oldPvDesign];
      if (oldPvDesign?.fileVersionId) {
        setDeletedFiles(newDeletedFiles);
      }
      updateSiteFiles(newAddedFiles, newDeletedFiles);
    } else {
      // Allow the same file guid to be "added" multiple times
      // and handle this by only including the file once in the list
      // of files to be uploaded
      const addedFilesWithoutFileGuid = addedFiles.filter(
        (f) => f.fileGuid !== siteFile.fileGuid
      );
      setAddedFiles([...addedFilesWithoutFileGuid, siteFile]);
      updateSiteFiles([...addedFilesWithoutFileGuid, siteFile], deletedFiles);
    }
  };

  const onFileDeleted = (siteFile) => {
    const existingSiteFile = siteFiles.find(
      (f) => f.fileVersionId === siteFile.fileVersionId
    );
    if (existingSiteFile?.fileVersionId) {
      setDeletedFiles([...deletedFiles, existingSiteFile]);
      updateSiteFiles(addedFiles, [...deletedFiles, existingSiteFile]);
    } else {
      const addedFilesWithoutFile = addedFiles.filter(
        (f) => f.fileGuid !== siteFile.fileGuid
      );
      setAddedFiles([...addedFilesWithoutFile]);
      updateSiteFiles([...addedFilesWithoutFile], deletedFiles);
    }

    if (siteFile.fileType === "pvDesignImage") {
      // need to clear the pv design image input value so that they
      // user can upload the same file again if they want
      pvDesignFileUploadRef.current.value = null;
    }
  };

  const onSaveClick = (updateHubSpot) => {
    setSaveDisabled(true);

    const nowEpochSeconds = Math.floor(Date.now() / 1000);
    if (
      previewSite.commerciallyOperationalDateEpochSeconds &&
      previewSite.commerciallyOperationalDateEpochSeconds < nowEpochSeconds &&
      previewSite.operationalStatus !== "operational"
    ) {
      setShowSaveModal(true);
      setUpdateHubSpotFromModal(updateHubSpot);
      return;
    }

    saveSite(updateHubSpot);
  };

  const saveSite = (updateHubSpot) => {
    const originalBuildingKeys = originalBuildings.map((b) => b.id);
    const newBuildingKeys = newBuildings.map((b) => b.key);

    const addedBuildings = [];
    const deletedBuildings = [];

    newBuildings.forEach((b) => {
      if (b.customised) {
        addedBuildings.push(b);
        return;
      }
      if (!originalBuildingKeys.includes(b.key)) addedBuildings.push(b);
    });

    originalBuildings.forEach((b) => {
      if (!newBuildingKeys.includes(b.id)) deletedBuildings.push(b);
    });

    // remove empty mpan pairs
    if (updatedSite?.siteMPANs?.length > 0) {
      const updatedSiteMPANs = updatedSite.siteMPANs.filter(
        (mpan) => mpan.importMPAN || mpan.exportMPAN
      );
      updatedSite.siteMPANs = updatedSiteMPANs.length
        ? updatedSiteMPANs
        : [{ importMPAN: "", exportMPAN: "" }];
    }

    const update = {
      site: {
        ...updatedSite,
        updateHubSpot,
      },
      addedBuildings,
      deletedBuildings,
      addedFiles,
      deletedFiles,
      newOwnerships: Object.values(newOwnerships),
      deletedOwnerships,
    };
    updateSite.mutate(update);
  };

  if (updateSite.isSuccess) {
    setSaveDisabled(false);
    updateSite.reset();
    window.location.reload();
  }

  if (updateSite.isError) {
    setSaveDisabled(false);
    alert(`Error updating site - ${updateSite.error.message}`);
    updateSite.reset();
  }

  const onDeleteSiteClick = () => {
    deleteSite.mutate(siteId);
  };

  if (deleteSite.isSuccess) {
    deleteSite.reset();
    navigate("/");
  }

  if (deleteSite.isError) {
    alert(`Error deleting site - ${deleteSite.error.message}`);
    deleteSite.reset();
  }

  const handleDiscardClick = () => {
    window.location.reload();
  };

  document.title = `Site: ${site.name}`;

  const setPvDesign = (pvPanelName, installedCapacity, pvSystems) => {
    const siteUpdate = {
      pvPanelName,
      installedCapacity,
      generationLossFactors: pvSystems.generationLossFactors,
      pvDesignSystemLastUpdatedAt: Math.floor(Date.now() / 1000),
      pvDesignSystemLastUpdatedBy: user.email,
    };

    if (site.pvPanelName !== pvPanelName) {
      // Only apply the default costs if the panel name has been changed
      siteUpdate.costInputsPv = pvSystems.costInputsPv;
    }

    setSiteState(siteUpdate);
    alert(
      `Updating site to use this PV Design. You need to save to commit the changes.`
    );
  };

  const deselectPvDesign = () => {
    setSiteState({
      pvDesignSystemLastUpdatedAt: null,
      pvDesignSystemLastUpdatedBy: null,
    });
  };

  return (
    <SiteCalculationsProvider site={site} setSaveDisabled={setSaveDisabled}>
      <SitePreview previewSite={previewSite} originalSite={site}>
        <SiteEditView
          site={previewSite}
          setSite={setSiteState}
          saveDisabled={saveDisabled}
          setSaveDisabled={setSaveDisabled}
          hasUnsavedBuildings={hasUnsavedBuildings}
          setHasUnsavedBuildings={setHasUnsavedBuildings}
          onSaveClick={onSaveClick}
          setBuildingsForPreview={setBuildingsForPreview}
          siteBuildings={site.buildings}
          ownerships={site.siteOwnerships}
          setOwnerships={setOwnerships}
          onOwnerRemoved={onOwnerRemoved}
          siteFiles={siteFiles}
          onFileAdded={onFileAdded}
          onFileDeleted={onFileDeleted}
          handleDiscardClick={handleDiscardClick}
          setPvDesign={setPvDesign}
          deselectPvDesign={deselectPvDesign}
          isOwnerOccupied={isOwnerOccupied}
          onBuildingProfileChange={onBuildingProfileChange}
          onDeleteSiteClick={onDeleteSiteClick}
          pvDesignUploadRef={pvDesignFileUploadRef}
          pvDesignDifference={pvDesignDifference}
          setPvDesignDifference={setPvDesignDifference}
          userEmail={user.email}
          showSaveModal={showSaveModal}
          setShowSaveModal={setShowSaveModal}
          updateHubSpotFromModal={updateHubSpotFromModal}
          saveSite={saveSite}
          navigationBlocker={navigationBlocker}
        />
      </SitePreview>
    </SiteCalculationsProvider>
  );
};

export default SiteEdit;

const SiteEditView = ({
  site,
  setSite,
  saveDisabled,
  setSaveDisabled,
  hasUnsavedBuildings,
  setHasUnsavedBuildings,
  onSaveClick,
  setBuildingsForPreview,
  siteBuildings,
  ownerships,
  onOwnerRemoved,
  siteFiles,
  onFileAdded,
  onFileDeleted,
  setOwnerships,
  onBuildingProfileChange,
  handleDiscardClick,
  setPvDesign,
  deselectPvDesign,
  isOwnerOccupied,
  onDeleteSiteClick,
  pvDesignUploadRef,
  pvDesignDifference,
  setPvDesignDifference,
  userEmail,
  showSaveModal,
  setShowSaveModal,
  updateHubSpotFromModal,
  saveSite,
  navigationBlocker,
}) => {
  const { errors, siteCalculations } = useContext(SiteCalculationsContext);

  useSetDefaultsOnCurrencyChange(site, setSite, siteCalculations.currencyCode);

  return (
    <>
      <SiteNav />
      <Container fluid style={{ padding: "12px 12px 0px 12px" }}>
        <Row>
          <Col>
            <SiteGeo
              site={site}
              initialSiteBuildings={siteBuildings}
              setSite={setSite}
              setBuildingsForPreview={setBuildingsForPreview}
              setNewSiteName={() => {}} // We don't want to change the site name of a created site
              mapHeight="600px"
              setHasUnsavedBuildings={setHasUnsavedBuildings}
            />
            <Demand
              site={site}
              setSite={setSite}
              errors={errors}
              siteCalculations={siteCalculations}
              onBuildingProfileChange={onBuildingProfileChange}
            />
            <Generation
              site={site}
              setSite={setSite}
              siteFiles={siteFiles}
              onFileAdded={onFileAdded}
              onFileDeleted={onFileDeleted}
              setSaveDisabled={setSaveDisabled}
              siteCalculations={siteCalculations}
              errors={errors}
              pvDesignUploadRef={pvDesignUploadRef}
              pvDesignDifference={pvDesignDifference}
            />
            <PvSystem
              site={site}
              setPvDesign={setPvDesign}
              deselectPvDesign={deselectPvDesign}
              setPvDesignDifference={setPvDesignDifference}
              userEmail={userEmail}
            />
            <Battery
              site={site}
              setSite={setSite}
              siteCalculations={siteCalculations}
              errors={errors}
              currencyCode={siteCalculations.currencyCode}
              isOwnerOccupied={isOwnerOccupied}
              setSaveDisabled={setSaveDisabled}
            />
          </Col>
          <Col>
            <SiteInformation
              site={site}
              offtakerOnlySite={
                siteCalculations.energyFlowAnnual.generation === 0
              }
              setSite={setSite}
              ownerships={ownerships}
              setOwnerships={setOwnerships}
              onOwnerRemoved={onOwnerRemoved}
              onBuildingProfileChange={onBuildingProfileChange}
              setNewSiteName={() => {}} // We don't want to change the site name of a created site
              errors={errors}
            />
            <DealTerms
              site={site}
              setSite={setSite}
              leaseLengthMonths={siteCalculations.leaseLengthMonths}
              errors={errors}
            />
            <Cost
              site={site}
              setSite={setSite}
              errors={errors}
              currencyCode={siteCalculations.currencyCode}
              initialCost={siteCalculations.projectCosts?.initialCostPerKWp}
            />
            <Tariff
              site={site}
              setSite={setSite}
              siteCalculations={siteCalculations}
              errors={errors}
            />
            <Capex
              siteCalculations={siteCalculations}
              currencyCode={siteCalculations.currencyCode}
            />
            <FinancialReturns
              siteCalculations={siteCalculations}
              currencyCode={siteCalculations.currencyCode}
              isOwnerOccupied={isOwnerOccupied}
            />
            <DnoNetwork
              site={site}
              setSite={setSite}
              siteCalculations={siteCalculations}
              errors={errors}
            />
            {site.operationalStatus === "operational" && (
              <SiteFiles
                site={site}
                siteFiles={siteFiles}
                onFileAdded={onFileAdded}
                onFileDeleted={onFileDeleted}
                setSaveDisabled={setSaveDisabled}
              />
            )}
            <ActivityLog
              siteId={site.id}
              createdAt={site.createdAt}
              createdByName={site.createdByName}
              createdByEmail={site.createdBy}
            />
          </Col>
        </Row>
        <Row>
          <ActionButtons
            handleDeleteClick={onDeleteSiteClick}
            handleDiscardClick={handleDiscardClick}
            handleSaveClick={() => onSaveClick(false)}
            handleUpdateHubSpotClick={() => onSaveClick(true)}
            saveDisabled={saveDisabled || hasUnsavedBuildings}
            site={site}
            errors={errors}
          />
        </Row>
        {showSaveModal ? (
          <Modal>
            <ModalView
              title="You've set an operational date in the past on a site that is not operational. Do you want to continue?"
              fontWeight="500"
              fontSize="16px"
              minHeight="450px"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginTop: "30px",
                }}
              >
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowSaveModal(false);
                    setSaveDisabled(false);
                  }}
                  style={{ width: "160px" }}
                >
                  No, go back
                </Button>
                <Button
                  variant="success"
                  onClick={() => {
                    saveSite(updateHubSpotFromModal);
                    setShowSaveModal(false);
                  }}
                  style={{ width: "160px" }}
                >
                  Yes, save anyway
                </Button>
              </div>
            </ModalView>
          </Modal>
        ) : null}
        {navigationBlocker.state === "blocked" && (
          <Modal>
            <ModalView
              title="You have unsaved changes. Do you want to continue?"
              fontWeight="500"
              fontSize="16px"
              width="600px"
              titlePadding="0"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "30px",
                  marginTop: "30px",
                }}
              >
                <Button
                  variant="secondary"
                  onClick={() => navigationBlocker.reset()}
                  style={{ flex: "1" }}
                >
                  No, go back
                </Button>
                <Button
                  variant="danger"
                  onClick={() => navigationBlocker.proceed()}
                  style={{ flex: "1" }}
                >
                  Yes, discard changes
                </Button>
              </div>
            </ModalView>
          </Modal>
        )}
      </Container>
    </>
  );
};
