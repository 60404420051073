import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap";

import { useState } from "react";
import { limitDecimalPlaces, numString, numString2dp } from "./utils";

import {
  getDefaultsPvSystem,
  useSite,
  useSiteFileUpload,
} from "@inrange/building-manager-api-client";

const Generation = ({
  site,
  setSite,
  siteFiles,
  onFileAdded,
  onFileDeleted,
  setSaveDisabled,
  siteCalculations,
  errors,
  pvDesignDifference,
  pvDesignUploadRef,
}) => {
  const { uploadSiteFile } = useSiteFileUpload(site.id);
  const currentPvDesignImage = siteFiles?.find(
    (file) => file.fileType === "pvDesignImage"
  );

  if (site.tenantAnnualDemandKwh === "0") {
    site.tenantAnnualDemandKwh = 0;
  }

  const roofUtilization =
    siteCalculations.halfHourlyGeneration?.roofUtilization;

  const showPvDesignImageUpload =
    onFileAdded && onFileDeleted && setSaveDisabled;
  const onPvDesignImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSaveDisabled(true);
      const newPvDesignImage = {
        fileType: "pvDesignImage",
        fileName: file.name,
        description: "PV Design Image",
      };
      uploadSiteFile.mutate(
        {
          file,
          siteFile: newPvDesignImage,
        },
        {
          onSettled: () => setSaveDisabled(false),
          onSuccess: onFileAdded,
          onError: (error) => {
            console.error("Error uploading PV design image", error);
          },
        }
      );
    }
  };

  return (
    <GenerationView
      site={site}
      setSite={setSite}
      errors={errors}
      currencyCode={siteCalculations.currencyCode}
      ghi={siteCalculations.halfHourlyGeneration?.ghi}
      panelCount={siteCalculations.pvPanelCount}
      annualGeneration={siteCalculations.energyFlowAnnual?.generation}
      annualNetworkExported={
        siteCalculations.energyFlowAnnual?.networkExport || 0
      }
      annualExported={siteCalculations.energyFlowAnnual?.exported || 0}
      annualCurtailed={siteCalculations.energyFlowAnnual?.curtailed || 0}
      roofUtilization={roofUtilization}
      showPvDesignImageUpload={showPvDesignImageUpload}
      currentPvDesignImage={currentPvDesignImage}
      onPvDesignImageUpload={onPvDesignImageUpload}
      onFileDeleted={onFileDeleted}
      pvDesignUploadRef={pvDesignUploadRef}
      pvDesignImageUploadInProgress={uploadSiteFile.isLoading}
      pvDesignDifference={pvDesignDifference}
    />
  );
};

const GenerationView = ({
  site,
  setSite,
  errors,
  currencyCode,
  ghi,
  panelCount,
  annualGeneration,
  annualNetworkExported,
  annualExported,
  annualCurtailed,
  roofUtilization,
  showPvDesignImageUpload,
  currentPvDesignImage,
  onPvDesignImageUpload,
  onFileDeleted,
  pvDesignUploadRef,
  pvDesignImageUploadInProgress,
  pvDesignDifference,
}) => {
  const [displayLossFactors, setDispayLossFactors] = useState(false);
  const { fetchSiteValues } = useSite({});
  const designSelected = !!site.pvDesignSystemLastUpdatedAt;

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Generation and PV design</strong>
      </Form.Label>
      {pvDesignDifference && (
        <Card.Text className={`text-danger d-block}`}>
          {designSelected ? "Update the design from" : "Select the design in"}{" "}
          the Automated PV design section to use the selected panel name,
          calculated installed capacity, and loss factor values.
        </Card.Text>
      )}
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="installedCapacity">
            <Form.Label>Installed capacity (kWp)</Form.Label>
            <Form.Control
              type="text"
              value={limitDecimalPlaces(site.installedCapacity, 3)}
              onChange={(e) =>
                setSite({
                  installedCapacity: limitDecimalPlaces(e.target.value ?? 0, 3),
                })
              }
              isInvalid={errors.installedCapacity}
              data-testid={"installedCapacity"}
            />
            <Form.Control.Feedback type="invalid">
              {errors.installedCapacity}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="annualGeneration">
            <Form.Label>Annual generation (kWh)</Form.Label>
            <Form.Control
              disabled
              value={numString2dp(annualGeneration) ?? ""}
              data-testid={"annualGeneration"}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="roofUtilisation">
            <Form.Label>Roof utilisation (%)</Form.Label>
            <Form.Control
              disabled
              value={parseFloat(roofUtilization ?? 0).toFixed(4) ?? ""}
              data-testid={"roofUtil"}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="pvPanel">
            <Form.Label>Panel used</Form.Label>
            <Form.Select
              value={site.pvPanelName}
              onChange={(e) => {
                const pvSystem = getDefaultsPvSystem(
                  fetchSiteValues.data.options.pvSystems,
                  currencyCode,
                  site.pvInverterBrand,
                  e.target.value
                );
                setSite({
                  pvPanelName: e.target.value,
                  generationLossFactors: pvSystem.generationLossFactors,
                  costInputsPv: pvSystem.costInputsPv,
                });
              }}
            >
              {Object.entries(fetchSiteValues.data.options.pvPanels).map(
                ([panelName, _], index) => (
                  <option key={index}>{panelName}</option>
                )
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="panelCount">
            <Form.Label>Panel count</Form.Label>
            <Form.Control
              disabled
              value={numString(panelCount) ?? ""}
              data-testid={"panelCount"}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="exported">
            <Form.Label>Exported (matched) (kWh)</Form.Label>
            <Form.Control
              disabled
              value={numString2dp(annualNetworkExported)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="pvInverterBrand">
            <Form.Label>Inverters used</Form.Label>
            <Form.Select
              value={site.pvInverterBrand}
              onChange={(e) => {
                const pvSystems = getDefaultsPvSystem(
                  fetchSiteValues.data.options.pvSystems,
                  currencyCode,
                  e.target.value,
                  site.pvPanelName
                );
                setSite({
                  pvInverterBrand: e.target.value,
                  generationLossFactors: pvSystems.generationLossFactors,
                  costInputsPv: pvSystems.costInputsPv,
                });
              }}
            >
              {Object.entries(
                fetchSiteValues.data.options.pvInverterBrands
              ).map(([inverterBrand, _], index) => (
                <option key={index}>{inverterBrand}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="curtailed">
            <Form.Label>Curtailed (kWh)</Form.Label>
            <Form.Control
              disabled
              value={numString2dp(annualCurtailed)}
              data-testid={"curtailed"}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="exported">
            <Form.Label>Exported (spill) (kWh)</Form.Label>
            <Form.Control disabled value={numString2dp(annualExported)} />
          </Form.Group>
        </Col>
        {showPvDesignImageUpload && (
          <Col md="auto">
            <Form.Group
              className="mb-3"
              style={{ display: "flex", flexDirection: "column" }}
              controlId="pvDesignImage"
            >
              <Form.Label>Designed PV image</Form.Label>
              <Form.Control
                type="file"
                style={{ display: "none" }}
                accept="image/*, application/pdf"
                ref={pvDesignUploadRef}
                onChange={onPvDesignImageUpload}
              />
              <Stack direction="horizontal" gap={3}>
                <label
                  htmlFor="pvDesignImage"
                  className="btn btn-success"
                  data-testid={"pvDesignImageUploadButton"}
                >
                  Choose file
                </label>
                <div data-testid={"pvDesignImageUploadFileName"}>
                  {!pvDesignImageUploadInProgress &&
                    (currentPvDesignImage?.fileName || "No file chosen")}{" "}
                  {currentPvDesignImage &&
                    !currentPvDesignImage.fileVersionId &&
                    " (unsaved)"}
                </div>
                <div>
                  {pvDesignImageUploadInProgress && <>(Uploading...)</>}
                  {currentPvDesignImage?.fileName &&
                    !pvDesignImageUploadInProgress && (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => onFileDeleted(currentPvDesignImage)}
                      >
                        Delete file
                      </Button>
                    )}
                </div>
              </Stack>
            </Form.Group>
          </Col>
        )}
        <Col>
          {!displayLossFactors && (
            <div
              style={{
                textAlign: "center",
                color: "rgb(13, 110, 253)",
                marginTop: "20px",
              }}
            >
              <a
                onClick={() => setDispayLossFactors(true)}
                style={{
                  cursor: "pointer",
                }}
              >
                Show loss factors
              </a>
            </div>
          )}
        </Col>
      </Row>
      {displayLossFactors && (
        <>
          {[
            <Col key="ghi">
              <Form.Group className="mb-3" controlId="ghi">
                <Form.Label>GHI (kWh/m²)</Form.Label>
                <Form.Control type="text" value={ghi ?? ""} disabled />
              </Form.Group>
            </Col>,
            ...fetchSiteValues.data.options.pvLossFactorLabels.map(
              (lossFactor, index) => (
                <Col key={index}>
                  <Form.Group className="mb-3" controlId={lossFactor.key}>
                    <Form.Label>{lossFactor.label}</Form.Label>
                    <Form.Control
                      type="text"
                      value={site.generationLossFactors[lossFactor.key] || 0}
                      onChange={(e) =>
                        setSite({
                          generationLossFactors: {
                            ...site.generationLossFactors,
                            [lossFactor.key]: e.target.value,
                          },
                        })
                      }
                      isInvalid={errors[lossFactor.key]}
                      data-testid={lossFactor.key}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors[lossFactor.key]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )
            ),
          ]
            .reduce((resultArray, item, index) => {
              const chunkIndex = Math.floor(index / 3);

              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
              }

              resultArray[chunkIndex].push(item);

              return resultArray;
            }, [])
            .map((row, index) => (
              <Row key={index}>
                <Col>{row[0]}</Col>
                <Col>{row[1]}</Col>
                <Col>{row[2]}</Col>
              </Row>
            ))}
          <Row></Row>
        </>
      )}
    </Card>
  );
};

export default Generation;
