// import axios from "axios";
import {
  getDefaultsPvSystem,
  useSite,
} from "@inrange/building-manager-api-client";
import { usePvSystemDesign } from "@inrange/inrange-data-api-client";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useQueryClient } from "react-query";
import { v4 } from "uuid";
import Loading from "../../Loading";
import PVCustomObjectsModal from "./PVCustomObjects/PVCustomObjectsModal";

const numString = (value) => {
  return new Intl.NumberFormat("en-GB", {
    style: "decimal",
  }).format(value);
};

const PvSystem = ({
  site,
  setPvDesign,
  deselectPvDesign,
  setPvDesignDifference,
  userEmail,
}) => {
  const { fetchSiteValues } = useSite({ siteId: site.id });
  const { getBooleanFlag } = useKindeAuth();
  const [showDebugData, setShowDebugData] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [timeSinceRefresh, setTimeSinceRefresh] = useState(0);
  const [pvMapLocation, setPvMapLocation] = useState(undefined);
  const [pvPanelNameSelected, setPvPanelNameSelected] = useState(
    site.pvPanelName
  );
  const [showCustomObjectsModal, setShowCustomObjectsModal] = useState(false);
  const [customObjects, setCustomObjects] = useState([]);

  const panel = fetchSiteValues.data.options.pvPanels[pvPanelNameSelected];
  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;
  // In test mode we don't invoke the PV Design
  // We do this by setting the panel to undefined, which disables the query
  const {
    query: { data, isFetching, isError, isSuccess, dataUpdatedAt },
    requestPvSystemDesign,
  } = usePvSystemDesign(
    site ? { id: site.id, name: site.name, user: userEmail } : undefined,
    testMode ? undefined : panel,
    site?.buildings,
    autoRefresh
  );
  const dataUpdatedAtRef = useRef(0);
  dataUpdatedAtRef.current = dataUpdatedAt;
  const queryClient = useQueryClient();

  const pvData = useMemo(() => data?.data || {}, [data]);

  useEffect(() => {
    setShowLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (pvData.task && !autoRefresh) {
      setAutoRefresh(true);
    } else if (!pvData.task && autoRefresh) {
      setAutoRefresh(false);
    }

    if (showCustomObjectsModal) {
      return;
    }

    let intervalId;
    if (autoRefresh) {
      intervalId = setInterval(() => {
        setTimeSinceRefresh(() => {
          const secondsSinceUpdate =
            (Date.now() - new Date(dataUpdatedAtRef.current)) / 1000;
          return Math.round(secondsSinceUpdate);
        });
      }, 100); // Run this every 100 ms
    } else {
      setTimeSinceRefresh(0);
    }

    if (pvData.custom_objects) {
      // Assign UUIDs to loaded objects
      const customObjectsWithId = Object.values(pvData.custom_objects).map(
        (obj) => {
          obj.id = v4();
          return obj;
        }
      );
      setCustomObjects(customObjectsWithId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isFetching, pvData, autoRefresh, showCustomObjectsModal]);

  const numBuildings = pvData.status?.num_buildings || 0;
  const completedPanelLayoutBuildings =
    pvData.status?.num_buildings_triggered || 0;
  const completedPanelRoofObjectIntersectionBuildings =
    pvData.status?.num_buildings_packed || 0;

  const totalMass = pvData.site_metrics?.total_mass.toFixed(1) || "0";
  const netForce = pvData.site_metrics?.net_force || "0";
  const deadLoad = pvData.site_metrics?.dead_load || "0";
  const roofAllocation = pvData.site_metrics?.roof_allocation || 0;
  const installedCapacity = Number(
    (pvData.site_metrics?.total_pnom / 1000 || 0).toFixed(3)
  );

  const updatedPvMapLocation = pvData.pv_map_location || undefined;

  // While a design is being built, we call the PV design endpoint every 30 seconds
  // and get back an updatedPvMapLocation. Rendering this every time will just use up
  // map tile requests which is unneccesary since we don't expect the map to be actually
  // different until the task has finished running.
  useEffect(() => {
    if (pvMapLocation === undefined && updatedPvMapLocation) {
      // First time we get the location, set it
      setPvMapLocation(updatedPvMapLocation);
    } else if (!autoRefresh && pvMapLocation !== updatedPvMapLocation) {
      // If we're not auto-refreshing, update the location
      setPvMapLocation(updatedPvMapLocation);
    } else if (
      !!pvMapLocation &&
      !!updatedPvMapLocation &&
      updatedPvMapLocation !== pvMapLocation
    ) {
      const oldExpires = parseInt(pvMapLocation.split("Expires=")[1]);
      const minutesSinceExpires = (Date.now() / 1000 - oldExpires) / 60;
      if (minutesSinceExpires > 30) {
        // If the map is at risk of expiring, update it
        setPvMapLocation(updatedPvMapLocation);
      }
    }
  }, [updatedPvMapLocation, pvMapLocation, autoRefresh]);

  const panelPowerKwp = panel.pnom / 1000;

  const showBuildingAnalysis =
    completedPanelRoofObjectIntersectionBuildings !== numBuildings ||
    pvData.task;

  const panelNameDifferent = pvPanelNameSelected !== site.pvPanelName;
  const installedCapacityDifferent =
    installedCapacity !== Number(site.installedCapacity);

  const pvSystem = getDefaultsPvSystem(
    fetchSiteValues.data.options.pvSystems,
    site.currencyCode,
    site.pvInverterBrand,
    pvPanelNameSelected
  );
  const lossFactorDifference = !_.isEqual(
    site.generationLossFactors,
    pvSystem.generationLossFactors
  );
  const designSelected = !!site.pvDesignSystemLastUpdatedAt;
  const anyDifference =
    !designSelected ||
    panelNameDifferent ||
    installedCapacityDifferent ||
    lossFactorDifference;

  const customObjectsCount = customObjects.length;
  const customBuildingCount = site.buildings.filter(
    (b) => b.polygonType === "custom"
  ).length;

  useEffect(() => {
    if (installedCapacity > 0) {
      setPvDesignDifference(anyDifference);
    } else {
      setPvDesignDifference(false);
    }
  }, [anyDifference, setPvDesignDifference, installedCapacity]);

  const rebuildDesign = async ({
    rebuildMap,
    rebuildDebugImages,
    forceRerun,
    forceRoofFetch,
    disableRoofObjectIntersection,
    customObjects = undefined,
  }) => {
    setShowLoading(true);
    await requestPvSystemDesign(
      site ? { id: site.id, name: site.name, user: userEmail } : undefined,
      panel,
      site?.buildings,
      {
        rebuild_map: rebuildMap,
        rebuild_debug_images: rebuildDebugImages,
        force_rerun: forceRerun,
        force_roof_fetch: forceRoofFetch,
        disable_roof_object_intersection: disableRoofObjectIntersection,
      },
      customObjects
    );
    // Once the new run has been triggered, clear the cache so that we show the details of
    // the newly launched task
    queryClient.invalidateQueries(["pv_system_design", site?.id, panel]);
  };

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Auto-generated PV system</strong>
      </Form.Label>
      <div className="mb-4 d-flex justify-content-between">
        <Button
          variant="success"
          onClick={() =>
            setPvDesign(pvPanelNameSelected, installedCapacity, pvSystem)
          }
          disabled={!site?.id || installedCapacity === 0 || !anyDifference}
        >
          {anyDifference
            ? designSelected
              ? "Update design"
              : "Select design"
            : "Design selected"}
        </Button>
        <Button
          variant="success"
          onClick={deselectPvDesign}
          disabled={!designSelected}
        >
          Deselect design
        </Button>
      </div>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-pvPanel">
            <Form.Label>Panel used</Form.Label>
            <Form.Select
              value={pvPanelNameSelected}
              onChange={(e) => setPvPanelNameSelected(e.target.value)}
            >
              {Object.entries(fetchSiteValues.data.options.pvPanels).map(
                ([panelName, _], index) => (
                  <option key={index}>{panelName}</option>
                )
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-installedCapacity">
            <Form.Label>Installed capacity (kWp)</Form.Label>
            <Form.Control disabled value={installedCapacity.toFixed(3)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-roofUtilisation">
            <Form.Label>Roof utilisation (%)</Form.Label>
            <Form.Control disabled value={roofAllocation.toFixed(4)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-panelCount">
            <Form.Label>Panel count</Form.Label>
            <Form.Control
              disabled
              value={
                pvData.site_metrics?.num_panels
                  ? numString(pvData.site_metrics?.num_panels)
                  : "??"
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-kwpPanel">
            <Form.Label>kWp per panel</Form.Label>
            <Form.Control disabled value={panelPowerKwp} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-totalMass">
            <Form.Label>Total panel mass (kg)</Form.Label>
            <Form.Control disabled value={totalMass} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-deadLoad">
            <Form.Label>
              Dead load (kN/m<sup>2</sup>)
            </Form.Label>
            <Form.Control disabled value={(deadLoad / 1000).toFixed(4)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-netForce">
            <Form.Label>Net force (kN)</Form.Label>
            <Form.Control disabled value={(netForce / 1000).toFixed(4)} />
          </Form.Group>
        </Col>
      </Row>
      {showBuildingAnalysis && (
        <>
          <p className="text-danger">
            {pvData.task_metrics && (
              <>
                Last PV Design task run{" "}
                {pvData.task_metrics.pv_design_available
                  ? "succeeded"
                  : "failed"}
                . Task was started at{" "}
                {pvData.task_metrics.start_time.replace(/\.\d+.*/, "")}.
              </>
            )}{" "}
          </p>
          <div className="text-danger">
            <ul>
              <li>
                <strong>
                  {completedPanelLayoutBuildings}/{numBuildings}
                </strong>{" "}
                buildings - PV panel initial layout completed
              </li>
              <li>
                <strong>
                  {completedPanelRoofObjectIntersectionBuildings}/{numBuildings}
                </strong>{" "}
                buildings - PV panel layout intersected with roof objects
              </li>
            </ul>
          </div>
          <p className="text-danger">
            {pvData.task && (
              <>
                Currently running PV Design task, started at{" "}
                {pvData.task.started_at.replace(/\.\d+.*/, "")}.
                <br />
              </>
            )}
          </p>
          {autoRefresh && (
            <p className="text-danger">
              Data last updated {timeSinceRefresh} seconds ago, updating every
              30 seconds.
            </p>
          )}
        </>
      )}
      {showLoading && <Loading height="500px" label="Loading PV Design" />}
      {isError && <div>Error loading PV system design.</div>}
      {!showLoading && !pvData.task && (
        <>
          <Form.Label>
            <strong>Site Map:</strong>
          </Form.Label>
          <div>
            <PvMap pvMapLocation={pvMapLocation} />
          </div>
        </>
      )}
      {isSuccess && (
        <>
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Button
                variant="secondary"
                onClick={() => setShowDebugData(!showDebugData)}
                style={{ margin: "10px 0 5px 0" }}
              >
                {showDebugData ? "Hide" : "Show"} debug data
              </Button>
            </Col>
            <Col sm={4} md="auto">
              <Row style={{ display: "block", textAlign: "right" }}>
                {customBuildingCount} custom site
                {customBuildingCount !== 1 && "s"}
              </Row>
              <Row style={{ display: "block", textAlign: "right" }}>
                {customObjectsCount} custom object
                {customObjectsCount !== 1 && "s"}
              </Row>
            </Col>
            <Col sm={4} md="auto">
              <Button
                variant="success"
                onClick={() => setShowCustomObjectsModal(true)}
                style={{ margin: "10px 0 5px 0", float: "right" }}
              >
                {customObjects.length > 0
                  ? "Edit customisation"
                  : "Customise objects"}
              </Button>
            </Col>
          </Row>
          {showDebugData && (
            <>
              {pvData.task && (
                <>
                  <div>
                    Stats from currently running task:
                    <ul>
                      <li>
                        Task Start Time:{" "}
                        {pvData.task.started_at.replace(/\.\d+.*/, "")}
                      </li>
                      <li>Task Status: {pvData.task.status}</li>
                    </ul>
                  </div>
                </>
              )}
              {pvData.task_metrics && (
                <>
                  <div>
                    Stats from last completed task:
                    <ul>
                      <li>
                        Start Time:{" "}
                        {pvData.task_metrics.start_time.replace(/\.\d+.*/, "")}
                      </li>
                      <li>
                        Run Time:{" "}
                        {pvData.task_metrics.run_time > 300
                          ? `${(pvData.task_metrics.run_time / 60).toFixed(
                              1
                            )} minutes`
                          : `${pvData.task_metrics.run_time.toFixed(0)} seconds`}
                      </li>
                      <li>
                        Task succeeded:{" "}
                        {`${pvData.task_metrics.task_run_success}`}
                      </li>
                      <li>
                        PV design available after task completed:{" "}
                        {`${pvData.task_metrics.pv_design_available}`}
                      </li>
                      {Object.entries(pvData.task_metrics.inputs?.options || {})
                        .length > 0 && (
                        <li>
                          Options:
                          <ul>
                            {Object.entries(
                              pvData.task_metrics.inputs.options
                            ).map(([key, value]) => (
                              <li key={key}>{`${key}: ${value}`}</li>
                            ))}
                          </ul>
                        </li>
                      )}
                    </ul>
                  </div>
                </>
              )}
              {pvData.debug &&
                Object.keys(pvData.debug)
                  .filter(
                    (building_id) =>
                      Object.keys(pvData.debug[building_id]).length > 0
                  )
                  .map((building_id, index) => {
                    return (
                      <div key={index}>
                        <div>Debug images for building: {building_id}</div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <div>
                              Panels before roof object intersection:{" "}
                              {
                                pvData[building_id]
                                  ?.num_panels_before_object_intersection
                              }
                            </div>
                            <div>
                              {pvData.debug[building_id]
                                .building_with_objects_image && (
                                <img
                                  src={
                                    pvData.debug[building_id]
                                      .building_with_objects_image
                                  }
                                  alt="building"
                                  width="100%"
                                />
                              )}
                            </div>
                          </div>
                          <div style={{ flex: 1 }}>
                            <div>
                              Panels after roof object intersection:{" "}
                              {
                                pvData[building_id]
                                  ?.num_panels_after_object_intersection
                              }
                            </div>
                            <div>
                              {pvData.debug[building_id]
                                .building_with_panels_with_objects_image && (
                                <img
                                  src={
                                    pvData.debug[building_id]
                                      .building_with_panels_with_objects_image
                                  }
                                  alt="building_with_objects"
                                  width="100%"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              {!pvData.task && (
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "5px" }}
                >
                  <Button
                    variant="danger"
                    onClick={async () =>
                      await rebuildDesign({
                        rebuildMap: true,
                        rebuildDebugImages: false,
                        forceRerun: false,
                        forceRoofFetch: false,
                        disableRoofObjectIntersection: false,
                      })
                    }
                  >
                    Recreate map
                  </Button>
                  <Button
                    variant="danger"
                    onClick={async () =>
                      await rebuildDesign({
                        rebuildMap: false,
                        rebuildDebugImages: true,
                        forceRerun: false,
                        forceRoofFetch: false,
                        disableRoofObjectIntersection: false,
                      })
                    }
                  >
                    Recreate debug images
                  </Button>
                  <Button
                    variant="danger"
                    onClick={async () =>
                      await rebuildDesign({
                        rebuildMap: false,
                        rebuildDebugImages: false,
                        forceRerun: true,
                        forceRoofFetch: false,
                        disableRoofObjectIntersection: false,
                      })
                    }
                  >
                    Rebuild
                  </Button>
                  <Button
                    variant="danger"
                    onClick={async () =>
                      await rebuildDesign({
                        rebuildMap: false,
                        rebuildDebugImages: false,
                        forceRerun: true,
                        forceRoofFetch: false,
                        disableRoofObjectIntersection: true,
                      })
                    }
                  >
                    Rebuild ignoring roof objects
                  </Button>
                  {getBooleanFlag("pv-design-force-rerun", false) && (
                    <Button
                      variant="danger"
                      onClick={async () =>
                        await rebuildDesign({
                          rebuildMap: false,
                          rebuildDebugImages: false,
                          forceRerun: true,
                          forceRoofFetch: true,
                          disableRoofObjectIntersection: false,
                        })
                      }
                    >
                      Rebuild with new satellite images
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
      {showCustomObjectsModal && (
        <PVCustomObjectsModal
          buildings={site.buildings}
          siteCenter={[site.latitude, site.longitude]}
          customObjects={customObjects}
          setCustomObjects={setCustomObjects}
          setShowModal={setShowCustomObjectsModal}
          triggerCustomObjectsRebuild={(customObjects) => {
            rebuildDesign({
              rebuildMap: false,
              forceRerun: true,
              forceRoofFetch: false,
              disableRoofObjectIntersection: false,
              customObjects,
            });
          }}
        />
      )}
    </Card>
  );
};

export default PvSystem;

const PvMap = ({ pvMapLocation }) => {
  if (pvMapLocation === undefined) return <i>Not yet available.</i>;

  return (
    <iframe title="pv design" src={pvMapLocation} width="100%" height="500px" />
  );
};
